import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';

import NewUserModalBody from './NewUserModalBody' 
import UpdateUserModalBody from './UpdateUserModalBody'
import UsersDataTD from './UsersDataTD'
import LinkClientsToCoordinator from "../Clients/LinkClientsToCoordinator";

import { RestUrls } from "./../../../Urls"

export default class Users extends Component {

    constructor(props) {

        super(props);
        this.state = {            
            usersList : this.props.pageData.usersList,
            newData : [],
            errorInsert : false,
            modalInsert : false,
            modalUpdate : false,
            linkCoordinator : false
        }

    }

    async loadData () {

        const API =  RestUrls.baseApiUrl + 'users';

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        //console.log(this.state);
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userIdUpdate : '',
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else if (data.usersList) {
            this.setState( {usersList : data.usersList});
        }
    }


    async deletteUser (rowId) {

        //console.log('DELETTE: ' + rowId);

        const API =  RestUrls.baseApiUrl + 'users?delleteUser=1';

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        //console.log(this.state);
        //console.log(user);

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code,
                rowId : rowId
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            //console.log(data);
            this.loadData();
        }
    }


    InsertUser = () => {
        //console.log('insert');
        this.modalHandle(true,'modalInsert');
    }

    editUser = (data) => {
        //console.log('UPDATE: ' + data );
        this.setState({ userIdUpdate : data },function() {this.modalHandle(true,'modalUpdate');})  
    }
 
    modalHandle = (value,modalName) => {
        this.loadData();
        this.setState({ [modalName] : value })
        
    }

    
    editCoordinatorClients =(coordinatorId) => {
        
        this.setState(
            {
                linkCoordinator: coordinatorId
            },function () {
                this.showHideModalCoordinator(true);
            }
        )
        

    }

    showHideModalCoordinator = (value=false) => {

        this.setState({ modalCoordinator : value })
    }

    render() {

        //let pageData = this.props.pageData;
        //console.log(pageData);

        //console.log(this.state.usersList);

        return (
            <div className="UsersPage animate__animated animate__fadeIn">

                <Button className="newUserBtn" onClick={() => this.InsertUser()} variant="secondary" size="sm"><i className="fas fa-plus" /> הוספת משתמש</Button>
                <h2 className="titStyle">ניהול משתמשים</h2>

                

                <div className="tableDisplay">

                    <div className="trTable titlesTr">
                        <div>שם מלא</div>
                        <div>שם משתמש</div>
                        <div>סוג משתמש</div>
                        <div>טלפון</div>
                        <div>מייל</div>
                        <div>#</div>
                    </div>
                    <div className="trTable contentTds" style={{background : 'none'}}>
                        <div className="vSpace"></div>
                    </div>
                    
                        {this.state.usersList.map(item => { 
                            
                            let nameFromId = this.props.pageData.userTypes.filter(function(desc) {
                                return desc.id === item.userType;
                            });

                            if (true) {
                            
                            //console.log( nameFromId[0]);

                            return <UsersDataTD 
                                            key={item.id}
                                            item={item}
                                            nameFromId={nameFromId}
                                            editCoordinatorClients = { () => this.editCoordinatorClients(item.id) }
                                            deletteUser={ () => this.deletteUser(item.id) }
                                            editUser={() => this.editUser(item.id)} />

                        } else return false}) }
                    
                </div>
    
                


                {/* https://react-bootstrap.netlify.app/components/modal/ */}

                {/* ADD USER MODAL */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                    animation={false}
                    onHide={() => this.modalHandle(false, 'modalInsert')}
                    /* size="sm" // בגודל קטן!!! */
                    /* size="lg" // גדול!! */
                    backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalInsert}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <span>הוספת משתמש חדש</span>
                        </Modal.Title>
                        <Button onClick={() => this.modalHandle(false,'modalInsert')} variant="secondary" >X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        < NewUserModalBody userTypes = {this.props.pageData.userTypes} loadData={this.loadData} />
                    </Modal.Body>
                </Modal>


                {/* EDIT USER MODAL */}
                <Modal
                    className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                    animation={false}
                    onHide={() => this.modalHandle(false,'modalUpdate')}
                    /* size="sm" // בגודל קטן!!! */
                    /* size="lg" // גדול!! */
                    backdrop="static" // חייב ללחוץ על כפתור!
                    /* centered // VERTICA CENTER */
                    show={this.state.modalUpdate}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <span>עריכת משתמש</span>
                        </Modal.Title>
                        <Button onClick={() => this.modalHandle(false,'modalUpdate')} variant="secondary" >X</Button>
                    </Modal.Header>
                    <Modal.Body>
                        < UpdateUserModalBody userIdUpdate = {this.state.userIdUpdate} userTypes = {this.props.pageData.userTypes} loadData={this.loadData} />
                    </Modal.Body>
                </Modal>



                <Modal
                        className='animate__animated animate__fadeInDown animate__faster coordinatorLinksModal'
                        animation={false}
                        onHide={() => this.showHideModalCoordinator(false)}
                        /* size="sm" // בגודל קטן!!! */
                        size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalCoordinator}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        {/* <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>שיוך לקוחות</span>
                            </Modal.Title>
                            
                        </Modal.Header> */}
                        <Modal.Body>
                            <Button onClick={() => this.showHideModalCoordinator(false)} variant="secondary" >X</Button>
                            <LinkClientsToCoordinator  screen="user" closeModal={this.showHideModalCoordinator} rowsSelected = {[]} coordinatorId = {this.state.linkCoordinator} />
                        </Modal.Body>
                    </Modal>



            </div>
        )
    }
}
