
import { RestUrls } from "./Urls"

export async function logout() {

    //console.log('IN');
    
  
    let currentUser = JSON.parse(localStorage.getItem('user-info'));
    let item = {userid : currentUser.data.id};
  
    //console.log(item);
  
    let result = await fetch(RestUrls.UserLogout, {
          method: 'POST',
          headers: {
            "Content-Type" : 'application/json',
            "Accept" : 'application/json'
          },
          body: JSON.stringify(item)
        });
  
        result = await result.json();
  
        //console.log(result); 
        
        localStorage.setItem('user-info','');
        window.location.reload();
  
        /* if(result.id) {
            localStorage.setItem('user-info',JSON.stringify(result));
            console.log(localStorage.getItem('user-info')); 
            history.push("/");
            console.log('YES1');
        } else {
          history.push("/login")
        } */
  }