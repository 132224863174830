import React, { Component } from 'react'
import { InputGroup, FormControl,Button, Form, Alert } from 'react-bootstrap';
import { ConstantsNames } from "./../../../Urls";

//import convertDate from './../../../../convertDateToDisplay'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import validator from "validator";
import loader from "../../../../img/preLoader.gif"
import InputMet from './../Finance/InputMet';


/* https://www.npmjs.com/package/react-datepicker */


function convertDate (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}


export default class OpenTaskModalBody extends Component {

    constructor(props) {

        super(props);

        let now = new Date();
        let current_datetime = new Date(now.toString().replace(/-/g, '/'));  

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;
    
        this.state = {
            upTask : current_datetime,
            doneTask : this.props.careDate,
            currenId : this.props.currenId,
            userId : user.id,
            seniorId : this.props.currenId,
            workerId : this.props.workerId,
            taskStatus : 1,
            typeTask: 2,
            comments : 'דיווחים למתש: ' + this.props.text + ' - ',
            textMatash :  this.props.text,
            idMatash :  this.props.idMatash,
            sent : false,
            filename : false,
            closeWorkerCode : '',

            mailResponse : false,
            mailAddresses : 'mail@mail.co.il',
            errors : false,
            loading: false
        };

        //console.log(this.state);
        //console.log(this.props.optionsStatusTasks);
        
    }

    sendMail = () => {
        
        var addresses = this.state.mailAddresses.split(',');
        let errors = [];

        if (!this.state.mailAddresses) {
            errors.push('אין כתובות מייל לשליחה');
        } else {

            addresses.forEach(function(mail){

                if(!validator.isEmail(mail)) {
                    errors.push(mail);
                }
                
                /* else if (!validator.isEmpty(mail)) {
                    errors.push('אין כתובות מייל לשליחה');
                } */

            });

        };

        if(errors[0]) {

            this.setState({errors : errors})

        } else {

            this.setState({ errors : false,  loading : true })

            
            
            //console.log('sendMail');
            this.sendtoAdmin('sendMail','Reports', {mails : this.state.mailAddresses, filename : this.state.filename },'mailResponse');

        }
        
    }


    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            if(url === 'reportBuilder') {
                this.setState({ sent : true })

                if(getData.filename) {
                    this.setState({ filename : getData.filename })
                }

                //this.props.modalShow(false);
            }

            /* else if (url === 'sendMail') {

                this.props.closeModal()

            } */


            

        }));
    }


     // INPUT
     handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    //For datePicker
    handleChangeDate = (date,nameInput) => {

        let newDate = convertDate(date);
        this.setState({ [nameInput]:newDate});
        
    }

    //SELECT
    editHandlerSelect = (event) => {
        this.setState({ [event.target.name] : event.target.value},function () {
            
            //console.log(this.state);

        });
    }

    sendForm = () => {


        let newData = this.state;

        let sendObj = {
            upTask: newData.doneTask,
            doneTask: convertDate (newData.upTask),
            typeTask: newData.typeTask,
            taskStatus: newData.taskStatus,
            seniorId: newData.seniorId,
            workerId: newData.workerId,
            comments: newData.comments,
            textMatash : newData.textMatash,
            idMatash :  newData.idMatash,
            closeWorkerCode : newData.closeWorkerCode
          };

        this.sendtoAdmin('reportBuilder','Reports',sendObj,'pdfMailResponse');
    }
    
    // INPUT
    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function(){
                //console.log(this.state);
            }
        );
        
    }

    render() {

        //console.log(this.props);
        let selectedDate1 = new Date(this.state.upTask.toString().replace(/-/g, '/'));  //report
        let selectedDate2 = new Date(this.state.doneTask.toString().replace(/-/g, '/'));  
        
        var md5 = require('md5');
        let txtDownload = this.state.filename ? ConstantsNames.base_url + '/Reports/downloadTXT?file=' + md5(this.state.filename) : '';

        let sendFlagBtn = (this.props.idMatash === '17' && !this.state.closeWorkerCode) ? false : true;

        return (
            <div>
                {!this.state.sent ? <div className="row">

                   <div className='divBoostrap col-sm-12'>
                            <InputGroup className="customInput dateInput">
                                
                                <div className='dateTimeCont'>
                                    <div className="customPrepend">תאריך דיווח</div>
                                    <DatePicker 
                                        showYearDropdown = {true}
                                        selected={ selectedDate2 }
                                        dateFormat='dd/MM/yy'
                                        className='inputPrepend form-control'
                                        name='upTask'
                                        locale="he"
                                        /* onChange={(date)=>this.handleChangeDate(date, 'upTask')} */
                                        disabled={true}
                                    />
                                </div>
                            </InputGroup>
                    </div>

                    <br/><br/>
                    { false ? <div>
                    <div className='divBoostrap col-sm-12'>
                            <InputGroup className="customInput dateInput">
                                
                                <div className='dateTimeCont'>
                                    <div className="customPrepend">תאריך תזכורת משימה</div>
                                    <DatePicker 
                                        showYearDropdown = {true}
                                        selected={ selectedDate1}
                                        dateFormat='dd/MM/yy'
                                        className='inputPrepend form-control'
                                        name='doneTask'
                                        locale="he"
                                        onChange={(date)=>this.handleChangeDate(date, 'doneTask')}
                                        disabled={this.props.is_disabled ? true : false}
                                    />
                                </div>
                            </InputGroup>
                    </div>

                    <br/><br/>

                    <div className='divBoostrap col-sm-12'>
                        <InputGroup className="customInput">
                            <InputGroup.Prepend className='inputPrependLabel'>
                            <InputGroup.Text id="basic-addon3">סטטוס משימה</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="select" 
                                className='inputPrepend selectPrepend'
                                onChange={this.editHandlerSelect} 
                                name='taskStatus'
                                value={this.state.taskStatus}
                                //disabled={this.props.is_disabled ? true : false}
                                //id="basic-url"
                                //aria-describedby="basic-addon3"
                                >
                                <option>בחר...</option>
                                {this.props.optionsStatusTasks.map(item => { if (true) {
                                        /* currentState={this.state} */
                                        return < option value ={item.data.id } key ={ item.data.id }> 
                                                    { item.data.name } 
                                                </ option >
                                    } else return false}) }
                                </FormControl>
                        </InputGroup>
                    </div>

                    <br/><br/>
                    
                    <div className='divBoostrap col-sm-12' >
                        <InputGroup className="customInput textArea">

                            <Form.Label style={{width : '50px'}}>הערות:</Form.Label>

                            <FormControl
                                style={{paddingRight : '70px'}}
                                as="textarea" rows={3}
                                className='inputPrepend'
                                onChange={this.handleChange} 
                                name='comments' 
                                value={this.state.comments}
                                disabled={this.props.is_disabled ? true : false}
                                />
                                
                        </InputGroup>
                    </div>
                </div> : false }

                    {this.props.idMatash === '17' ? <div className='divBoostrap col-sm-12'>

                        <InputGroup className="customInput">
                            <InputGroup.Prepend className='inputPrependLabel'>
                            <InputGroup.Text id="basic-addon3">סיבת סיום עבודה</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="select" 
                                className='inputPrepend selectPrepend'
                                onChange={this.editHandlerSelect} 
                                name='closeWorkerCode'
                                value={this.state.closeWorkerCode}
                                //disabled={this.props.is_disabled ? true : false}
                                //id="basic-url"
                                //aria-describedby="basic-addon3"
                                >
                                <option>בחר...</option>

                                <option value = '06' >התפטרות</option>
                                <option value = '01' >משהו אחר</option>
                                <option value = '02' >משהו אחר1</option>
                                
                                </FormControl>
                        </InputGroup>
                    </div> : false }

                    
                    <div className='divBoostrap col-sm-12' style={{margin: '30px 0 0 0', textAlign : 'center'}}>
                        {/* variant={error ? 'danger' : 'success' } */}
                        <Button variant={sendFlagBtn ? 'primary' : 'secondary' } disabled={sendFlagBtn ? false : true} size="lg" onClick={this.sendForm} >שליחה</Button>
                    </div>
                    

                </div> : <div className='sentForm'>

                    <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                    {this.state.filename ? <div>
                        <h2  className='animate__animated animate__bounce'>נוצר בהצלחה</h2>

                        <div className="row">
                            
                            <div style={{ margin : '20px 0 -10px 0',  padding : '0 5%' }} className="col-12 sendMailForm">

                                {this.state.mailResponse ? console.log(this.state.mailResponse) : false}
                                        
                                        <InputMet className='' value={this.state.mailAddresses} text='כתובת מייל לשליחה' as={false} name='mailAddresses' submit={this.submit} />

                                        {this.state.errors[0] ? <Alert style={{margin : '10px 0 0px 0'}} variant="danger">

                                            <h3 style={{ fontSize : '20px' }} >שגיאה בכתובת מייל: </h3>

                                            {this.state.errors.map(item => { if (true) {
                                                    return <p key={item} style={{fontSize: '16px', margin: '0 0 20px 0'}}>{item}</p>
                                            } else return false}) }

                                        </Alert> : false }

                                    {this.state.mailResponse ? <h2  style={{margin: "20px 0 0 0", fontSize : '26px'}} className='animate__animated animate__bounce'>המייל נשלח בהצלחה</h2> : false }

                            </div>

                        </div>

                        <div className="row" style={{textAlign :  'center', margin : '30px 0 0 0'}}>
                            
                            <div className="col-5">
                                <Button style={{width : '100%'}} variant='success' size="" href={txtDownload} target="_blank" rel="noopener noreferrer" >להורדת הקובץ &raquo;</Button>
                            </div>

                            <div className="col-5">
                                <Button  style={{width : '100%'}} variant="primary" disabled={ this.state.loading || !this.state.mailAddresses ? true : false } onClick={this.sendMail} size=""  >שליחת מייל/ים</Button>
                            </div>

                            <div className="col-2">
                                <Button style={{width : '100%'}} variant='secondary' size="" onClick={ ()=> this.props.closeModal() }  >סגור</Button>
                            </div>
                        </div>

                    </div> : <h2  style={{ color : 'red'}} className='animate__animated animate__bounce'>שגיאה</h2> }
                
                </div> }
            </div>
        )
    }
}
