import React, { Component } from 'react'
import { RestUrls } from "../../../Urls";
import InputFormAdmin from "../../../DinamicForm/InputFormAdmin";
//import ReportIt from "./ReportIt" 
//import Contacts from "./Contacts" 
import { Button, Modal, Accordion, Card } from 'react-bootstrap';

import { sendToAdmin } from "./../../../DinamicForm/sendToAdmin"
//import { addSection } from "./../../../DinamicForm/addSection"

import FilesWorker from "./FilesWorker"
import LinksWorker from "./LinksWorker"
import RecommendsWorker from "./RecommendsWorker"

import OpenTaskModalBody from './../HomePage/Tasks/OpenTaskModalBody'
import WsignIndex from './../Wsign/WsignIndex';

//import convertDate from './../../../convertDateToDisplay'

export default class WorkerScreen extends Component {

    constructor(props) {

        super(props);
        this.state = {            
            dataPage : [],
            isLoaded: false
        }

    }

    async componentDidMount() {

        localStorage.setItem('searchInner','');
        localStorage.setItem('currentPathSearch','');

        const API =  RestUrls.baseApiUrl + 'foreignWorkers/'+ this.props.workerId;

        //console.log(API);

        let user = this.props.user;
        

        let userPass = { 
                userId : user.id,
                userName : user.username,
                userCredential : user.userCredential,
                token : user.token,
                siteCode :  RestUrls.Code
            };

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(userPass)
          });


        const data = await res.json();

        //console.log(data);
       
        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } else {
            this.setState(
                {
                  dataPage: data,
                  isLoaded: true
                }
            )
        }
  
        //console.log(data);
    }


    changeArrayRecommends = () => {
        setTimeout(() => this.componentDidMount(), 500);
    }


    editHandler = (selectedClientId) => {
        this.props.cancelId(selectedClientId);
    }


    insertRecommendsWorker = (url) => {
        sendToAdmin(url);
        setTimeout(() => this.componentDidMount(), 100);
    }

    newTask = (value) => {
        //console.log('IN TASK');
        this.setState({ modalShow : value })
    }

    onChangeAdmin = () => {
        //console.log('changed');
        
        setTimeout(() => this.setState({savedMessage: true}), 1000);
        setTimeout(() => this.setState({savedMessage:false}), 4000);
    }

    render() {
        if (!this.state.isLoaded) {
            return <div>טוען...</div>
        }

        else {

            /* console.log(this.props); */

            
            //let urlDelleteTwice = 'clients/' + this.props.clientId + '?delleteTwiceContact=1';
            //let urlInsertsection = 'clients/' + this.props.clientId;

            //formRecommendsWorker
            let urlInsertRecommend = 'foreignWorkers/' + this.props.workerId + '?insertRecommend=1';
            let WorkerData = this.state.dataPage.dataParam;

            return (
                <div className="clientScreen workerScreen">
                    <header className="newTask">
                        <button style={{marginLeft: 0}} className='newTaskBtn' onClick ={()=>this.newTask(true)} >משימה חדשה</button>
                        {/* <button className='newTaskBtn' onClick ={()=>console.log('NOT SET')} >בדיקה מול משרד הפנים</button> */}
                    </header>

                    {this.state.savedMessage ? <div className="savedWindow animate__animated animate__bounceIn animate__slow">השינוים נשמרו בהצלחה</div> : false }

                    <button className='goBackCustomBtn bounce' onClick ={()=>this.editHandler(false)} >&laquo; חזור</button> <h2 className="titStyle firstH2">מסך עובד: {WorkerData.name} {WorkerData.surname}</h2>

                    <div className="row formContainerAdmin">
                        {this.state.dataPage.form.map(item => { if (true) {
                            /* currentState={this.state} */
                            return <InputFormAdmin
                                        platform={this.props.platform}
                                        onChange={this.onChangeAdmin}
                                        Prepend={true}
                                        user={this.props.user}
                                        methodName='foreignWorkers'
                                        updateGetAction='?update=1'
                                        data={item}
                                        rowsOption4Select = {this.state.dataPage.rowsOption4Select}
                                        dataParam={this.state.dataPage.dataParam}
                                        key={item.name} />
                        } else return false}) }
                    </div>

                    <div className="accordionSec">

                        {/* defaultActiveKey="10" */}
                        <Accordion   >
                            <Card>
                                <Card.Header>

                                    <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                        <h2 className="titStyle ">חתימה דיגיטלית של מסמכים</h2>
                                    </Accordion.Toggle>

                                </Card.Header>
                                <Accordion.Collapse eventKey="10">

                                <Card.Body>
                                    <WsignIndex clientId={false} workerId={WorkerData.id} />
                                </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    
                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <h2 className="titStyle ">ארכיון מסמכים</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <section className="filesWorker">
                                            <h2 className="titStyle">ארכיון מסמכים</h2>
                                            <FilesWorker  allFilesWorker={this.state.dataPage.allFilesWorker} workerId={this.state.dataPage.dataParam.id} seniorId='' user={this.props.user} />
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        <h2 className="titStyle ">היסטורית שיבוצים</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <section className="linkedWorker">
                                            <h2 className="titStyle">היסטורית שיבוצים</h2>
                                            <LinksWorker linkedClients = {this.state.dataPage.linkedClients} />
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Accordion >
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        <h2 className="titStyle ">המלצות</h2>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <section className="RecommendsWorker">
                                            <h2 className="titStyle">המלצות</h2>
                                            {this.state.dataPage.recommendsWorker.length > 0 ? <RecommendsWorker changeArrayRecommends={this.changeArrayRecommends} platform={this.props.platform} dataPage={this.state.dataPage} user={this.props.user} /> : ''}
                                            <div className='clear' >
                                                <Button  className={this.state.dataPage.recommendsWorker.length > 0 ? "addBtn" : "addBtn addSection"} onClick={() => this.insertRecommendsWorker(urlInsertRecommend)} variant="secondary" size="sm">הוספת שורה <i className="fas fa-plus" /></Button>
                                            </div>
                                        </section>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                    </div>
                    

                    

                    
                    


                    {/* https://react-bootstrap.netlify.app/components/modal/ */}
                    <Modal
                        className='animate__animated animate__fadeInDown animate__faster newTaskModal'
                        animation={false}
                        onHide={() => this.newTask(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalShow}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>הוספת משימה חדשה</span>
                            </Modal.Title>
                            <Button onClick={() => this.newTask(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        <Modal.Body>
                            <OpenTaskModalBody currenId='' workerId={this.state.dataPage.dataParam.id} optionsTaskTypes={this.state.dataPage.taskTypes} optionsStatusTasks={this.state.dataPage.tasksStatus} />
                        </Modal.Body>
                    </Modal>

                </div>
            )
        }
    }
}
