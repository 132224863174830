var md5 = require('md5');
let code = 'seb-Major!wd+=111@$%+asd';

//console.log(md5(today));

var baseApiUrl = 'https://major.wdev.co.il/api/First/';
var baseApiUrl1 = 'https://major.wdev.co.il/api/';

export const RestUrls = {
    Code: md5(code),
    baseApiUrl : baseApiUrl,
    baseApiUrl1 : baseApiUrl1,
    filesMethod : baseApiUrl + 'uploadFiles',
    SiteData: baseApiUrl + 'SiteDataItems',
    UserValidation: baseApiUrl + 'loginUser',
    UserLogout: baseApiUrl + 'logoutUser',
    PageData: baseApiUrl + 'first/',
    Pages: baseApiUrl,
    Leads: baseApiUrl + 'leadform'
}   

export const ConstantsNames = {
    base_url : 'https://major.wdev.co.il/api',
    pic :   baseApiUrl1 + 'assets/img/pictures/',
    files : baseApiUrl1 + 'assets/files/',
    pic2 : baseApiUrl1 + 'assets/img/',
    version : Math.floor(Math.random() * 100000)
    //version : 1
}