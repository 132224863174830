//import logo from './logo.svg';
import './App.css';

import './css/site.css';
import './css/site_pages.css';
import './css/mobile.css';

//import './css/media.css';
//import './css/accessibility.css';

//https://getbootstrap.com/ //npm install bootstrap@next

import React,{ Component }  from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; //npm install react-router-dom
import LoginPage from "./Components/loginPage"
import GetPage from "./Components/getPage"

import IdleTimer from 'react-idle-timer' //time out timmer
//npm install react-idle-timer --save

export default class App extends Component {
 
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  render() {

      //console.log('Current User: ' + localStorage.getItem('user-info'));
      return (
          <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            //http://72.9.151.42/time/minutes-to-milliseconds-conversion.html
            timeout={1000 * 60 * 30}  // 30 minute
            //timeout={1000 * 60 * 1} // 1 mitutes
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />

            <React.Fragment>

            <Router>
              <Switch>
                {/* .length > 0 */}
                { localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0 ?
                      <Route path={"/"} component={GetPage} /> :  <Route path={"/"} component={LoginPage} />
                }
              </Switch>
            </Router>

            </React.Fragment>

        </div>
      )
  }

  handleOnAction (event) {
    //console.log('user did something', event)
  }

  handleOnActive (event) {
    //console.log('user is active', event)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    
    localStorage.setItem('user-info','');
    window.location.reload();

    //console.log('user is idle', event)
    //console.log('last active', this.idleTimer.getLastActiveTime())
  }

}   


