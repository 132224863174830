import React, { Component } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap';
import ReportVisit from './ReportVisit';
import LastPayedClient from './LastPayedClient';
import ReportVisa from './ReportVisa';
import ReportLicenseClient from './ReportLicenseClient';

export default class Reports extends Component {

   
    constructor(props) {
        super(props)
    
        this.state = {
            selectedAction : ''
        }
    }


    selectedAction = (event) => {

        this.setState({selectedAction :  event.target.value })
    }

    changeScreen = (screenName = false) => {
        
        this.setState({selectedAction :  screenName })

    }
   

    render() {

        //console.log(this.props);

        let rakazot = this.props.pageData.rakazot;

        return (
            <div className="ReportsPage animate__animated animate__fadeIn">

                <h2 className="titStyle">דוחות</h2>

                <div className="row justify-content-center">
                  <div className="col-lg-6 text-center">
                        <div className="contSelect">

                            {/* <h2 className='blueH2'>בחר</h2> */}
                            <InputGroup className="customInput" >

                                <InputGroup.Prepend className='inputPrependLabel '>
                                    <InputGroup.Text>סוג דו"ח</InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.selectedAction} 
                                    value={this.state.selectedAction}
                                    name="selectedAction"
                                >

                                    <option value="">בחר...</option>

                                    <option value="נספח ביקורי בית">נספח ביקורי בית</option>
                                    <option value="פקיעת הסכמי מסגרת לפי לקוחות">פקיעת הסכמי מסגרת לפי לקוחות</option>
                                    <option value="פקיעת תוקף היתר העסקה ללקוח">פקיעת תוקף היתר העסקה ללקוח</option>
                                    <option value="פקיעת תוקף ויזה">פקיעת תוקף ויזה</option>
                                    
                                    
                                    {/* <option value=""></option> */}
                                
                                </FormControl>

                            </InputGroup>
                        </div>
                    </div>
                </div>
                    
                
                
                {this.state.selectedAction === 'נספח ביקורי בית' ? <ReportVisit changeScreen={(screenName) => this.changeScreen(screenName) } /> : false }

                {this.state.selectedAction === 'פקיעת הסכמי מסגרת לפי לקוחות' ? <LastPayedClient rakazot = {rakazot} changeScreen={(screenName) => this.changeScreen(screenName) } /> : false }

                {this.state.selectedAction === 'פקיעת תוקף ויזה' ? <ReportVisa changeScreen={(screenName) => this.changeScreen(screenName) } /> : false }

                {this.state.selectedAction === 'פקיעת תוקף היתר העסקה ללקוח' ? <ReportLicenseClient rakazot = {rakazot} changeScreen={(screenName) => this.changeScreen(screenName) } /> : false }

                
                
            </div>
                
        )
    }
}
