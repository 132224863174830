import React, { Component } from 'react'
import { Button, Alert } from 'react-bootstrap';
import PayItem from './PayItem';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import loader from "../../../../img/preLoader.gif"


function toNumberAndFix (number) {

    let newNumber = parseFloat(number);

    return newNumber.toFixed(2)

}



export default class ShowPays extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            invoiceId : this.props.currentState.invoiceId,
            clientId : this.props.currentState.clientId,
            paysData : this.props.currentState.payData,
            is_invoiceOk : false,
            invoiceBuilded : false,
            errorTotal : this.props.errorTotal ? this.props.errorTotal : false,
            editInvoice : this.props.editInvoice,
            loading: false
        }
    }


    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData, loading : false },function(){

            if( stateNameResponse === 'invoiceBuilded') {

                if(getData.buildTranzilaError) {
                    alert('שגיאה');
                } else {
                    this.props.is_payed();
                }

                //console.log('getData');
                //this.props.is_payed();

            }

        }));
    }

    
    buildInvoiceController = () => {
        
        let payData = this.props.currentState.payData;
        let superTotal = toNumberAndFix(payData.invoice.superTotal);
        let totalPays = toNumberAndFix(payData.totalpays);

        //console.log('superTotal: ' + superTotal);
        //console.log('totalPays: ' + totalPays);
        //console.log(this.state.is_invoiceOk);

        //console.log(payData);

        if( (totalPays === superTotal) && !this.state.is_invoiceOk)  {
            //this.props.is_payed();
            this.setState({is_invoiceOk : true});
        } else if (totalPays > superTotal && !this.state.errorTotal) {
            this.setState({errorTotal : 'יש תשלומים גדולים יותר מסכום החשבונית.',
                           is_invoiceOk : false});
        }

        //console.log(this.state.errorTotal);

    }

    componentDidUpdate () {
        this.buildInvoiceController();
    }

    componentDidMount () {
        this.buildInvoiceController();
    }
    
    buildMyInvoice = () => {

        //console.log('buildMyInvoice');
        this.setState({ loading : true });

        let receiptInvoice;

        if(this.props.editInvoice) {

            receiptInvoice = '1';

        } else {
            receiptInvoice = '0';
        }

        //console.log(receiptInvoice);

        this.sendtoAdmin('buildMyInvoice','pays', { invoiceId : this.state.invoiceId, receiptInvoice :  receiptInvoice },'invoiceBuilded');

    }

    render() {

        let payData = this.props.currentState.payData;
        let superTotal = toNumberAndFix(payData.invoice.superTotal);
        let totalPays = toNumberAndFix(payData.totalpays);

        let rest = toNumberAndFix(superTotal - totalPays);
        
        


        return (
            <div className="ShowPays">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                {!this.props.currentState.payData.creditCardErrorTranzila && !this.state.editInvoice ?
                    <h2 className='animate__animated animate__bounce insertOk' >התשלום התקבל בהצלחה.</h2> :false }

                <div className="ShowPaysCont">

                { payData.invoice.invoiceType !== 'חשבונית מס' || this.props.editInvoice ? <div>

                        <header>
                            <p>{totalPays < superTotal ? 'חסר' : ''} {totalPays > superTotal ? 'סכום יתר' : ''} <span className={totalPays !== superTotal ? 'redClass' : ''}>₪ {rest}</span> מ- 
                            <strong>{superTotal} ₪</strong>
                            </p>

                            <h3>שולם: </h3>
                        </header>
                        
                        {payData.pays[0]  ? <ul className="paysItems">
                            {payData.pays.map(item => { if (true) {
                                return <PayItem key = {item.id} item={item} reLoadData={()=>this.props.reLoadData()} clientId = {this.props.currentState.clientId} />
                            } else return false}) }
                            
                        </ul> : false }

                    </div> : false }
                    
                    <div className="invoiceBuild">

                        {this.state.errorTotal && this.props.errorTotal !== 'reset' ? <Alert variant="danger">
                            <p style={{fontSize: '16px', margin: '0'}}>{this.state.errorTotal}</p>
                          </Alert> : false }

                        <div style={{textAlign : 'center', padding : '20px 0 0px'}}>
                            <Button className={this.state.is_invoiceOk ? 'animate__animated animate__heartBeat' : ''} onClick={ this.buildMyInvoice } size='lg' disabled={this.state.is_invoiceOk ? false : true }  variant={this.state.is_invoiceOk && !this.state.loading ? "success" : 'secondary' }  >יצירת חשבונית</Button>
                        </div>
                        
                    </div>    

                </div>
                
            </div>
        )
    }
}
