import React, { Component } from 'react'

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
//import { InputGroup, FormControl } from 'react-bootstrap';

import SearchAutocompleteAndDbFocusClients from './SearchAutocompleteAndDbFocusClients'
import convertDate from './../../../convertDateToDisplay'
import {getAllUrlParams} from "./../../../UrlParameters"

import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';

import SignatureCanvas from 'react-signature-canvas';

import { Modal } from 'react-bootstrap';

registerLocale('he', he)



//https://www.npmjs.com/package/react-signature-canvas
//Draw over image
//https://jsfiddle.net/szimek/d6a78gwq/ 

//https://morioh.com/p/f36c16f81697



function convertDate2 (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}


export default class HomeVisits extends Component {

    constructor(props) {

        super(props);

        let today = new Date();

        this.state = {            
            data : '',
            dataClient : false,
            visitType : '',
            comments : '',
            created_at : new Date(today.toString().replace(/-/g, '/')),
            trimmedDataURL : null,
            responseVisit : false,
            selectedClientId : '',
            chosenSocialWorker : ''
        }

    }

    loadData = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        //console.log('IN');

        //let objectToSend = {hello : '1'}

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData }));
    }

    componentDidMount() {
        let urlParams = localStorage.getItem('currentPathSearch') ? localStorage.getItem('currentPathSearch') : false;
        let urlQuery = urlParams ? getAllUrlParams(urlParams) : false;

        //console.log(urlQuery);

        if(urlQuery.id) {
            
            this.setState({ selectedClientId : urlQuery.id },function(){
                this.loadData('getclientData','homeVisits', {clientId : urlQuery.id},'dataClient');
            });

        }

        

        //this.loadData('getclients','homeVisits');
        //
    }


    submitVisit = () => {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        if( !this.state.visitType ||  !this.state.trimmedDataURL || (user.userCredential !== 'socialWorker' && !this.state.chosenSocialWorker) ) {

            this.modalErrors(true);

        }  else {


            let sendData = {
                created_at : this.state.created_at,
                seniorId : this.state.selectedClientId,
                visitType : this.state.visitType,
                chosenSocialWorker : this.state.chosenSocialWorker,
                comments : this.state.comments,
                sign : this.state.trimmedDataURL
            }
    
            console.log(sendData);
    
            this.loadData('addVisit','homeVisits', sendData,'responseVisit');
    
            let today = new Date();
    
            this.setState({ 
                
                created_at : new Date(today.toString().replace(/-/g, '/')),
                visitType : '',
                comments : '',
                trimmedDataURL : null        
            
            },function(){
                this.sigPad.clear();
                setTimeout(() => this.loadData('getclientData','homeVisits', {clientId : this.state.selectedClientId },'dataClient'), 100);
            });


        }

        

        

    }
    


    handleSearchSelected = (item) => {

        this.setState({ selectedClientId : item.id },function() {

            this.loadData('getclientData','homeVisits', {clientId : item.id},'dataClient');

        });
        
    }

    //For datePicker
    handleChangeDate = (date,nameInput) => {

        let newDate = convertDate2(date);
        //console.log(newDate);

        
        this.setState({ [nameInput]:newDate},function(){
            console.log(this.state);
        });
        
    }

    // INPUT
    handleChange = (event) => {

        
            this.setState({ [event.target.name] : event.target.value},
                function(){
                    console.log(this.state);
                    //this.props.submit( {[event.target.name] : this.state.inputValue});
            });
        
        
    }


    
    endSign = () => {
        this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png') }, function()
            {
                //console.log(this.state.trimmedDataURL);
            })
        console.log('end');
        
    }

    onBegin = () => {
        console.log('onBegin');
    }

    //sigPad = {}
    sigPad = {
        
    }

    clear = () => {
      this.setState({ trimmedDataURL: null },function() {this.sigPad.clear()})
    }
  
    trim = () => {
      this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png') }, function(){console.log(this.state.trimmedDataURL);})
    }

    modalErrors = (value) => {
        this.setState({ modalShow : value })
    }
    

    render() {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let choises = [
            'ביקור לפני השמה',
            'ביקור אחרי השמה (30 יום אחרי)',
            'ביקור חצי שנתי',
            'אחר'
        ];


        let inputValue = this.state.created_at;
        
        let today = new Date();
        let current_datetime = inputValue === ''  ? new Date(today.toString().replace(/-/g, '/')) : new Date(inputValue.toString().replace(/-/g, '/'));  

        return (

            <div className="homeVisitsPage">
                <h2 className="titStyle">ביקורי בית</h2>

                {this.state.responseVisit ? console.log(this.state.responseVisit) : false}

                <div className="searchClients">
                    {user.userCredential !== '1' ? <div>

                    { true ? <div className="row justify-content-center selectorContent">
                        
                        <div className="col-lg-4 text-center selectClientsWorkers">
                        
                            <div className="row">
                        
                                <div className="SearchAutocomplete" style={{ width: '100%'}} >
                                    
                                    { user.userCredential !== 'socialWorker' || (user.userCredential === 'socialWorker' && parseInt(this.props.pageData.countClients) >= 1) ?
                                    <SearchAutocompleteAndDbFocusClients keys={["name"]} coordinatorId={user.id} placeholder="חיפוש לקוחות" submit={this.handleSearchSelected} DBItems={'homeVisits/getclients?searchData=1'} />
                                    : <h2 style={{color: 'red'}}>אין לקוחות משוייכים</h2>}
                                </div>
                        
                    </div>
                        </div>
                    </div> : false }

                    </div> : false}
                </div>


                {this.state.dataClient ? <section className="clientDataVisit">

                    <h2 className="titStyle">לקוח - {this.state.dataClient.client.name + ' ' + this.state.dataClient.client.surname}
                    <a className="jumpTo" href={'לקוחות/?id=' + this.state.dataClient.client.id }>לפרטי לקוח &raquo;</a></h2>
                    

                    {this.state.dataClient.homeVisitsLog[0] ? <div className="tableComments SeniorContacts">
                            <div className="seniorsTableContact">
                                <div className="trTable titlesTr">
                                    <div className="tdTable">תאריך</div>
                                    <div className="tdTable">סוג ביקור</div>
                                    <div className="tdTable">שם עובדים סוציאלים</div>
                                    <div className="tdTable">הערות</div>
                                    <div className="tdTable">חתימה</div>
                                </div>
                                <div className="trTable contentTds">
                                    <div className="vSpace"></div>
                                </div>

                                {this.state.dataClient.homeVisitsLog.map(item => { if (true) {
                                    /* currentState={this.state} */
                                    return  <div key={item.id} className="trTable contentTds">
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{convertDate(item.created)}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.name}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.socialWorker_name}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name"><p>{item.comments}</p></div>
                                            <div className="col-md-2 divBoostrap col-sm-12 name">{item.sign ? <img src={item.sign} alt='sign'/> : null}</div>
                                        </div>
                                } else return false}) }
                            </div> 
                        </div> :  false } {/* <h3 style={{fontSize : '18px'}}>אין הערות קודמות</h3> */}


                        <div className="insertNewVisit">


                            <div className="row justify-content-center selectorContent">
                        
                                <div className="col-lg-6 text-center selectClientsWorkers">

                                        <h2 className="titStyle" style={{border:'none', margin : '20px 0 30px 0'}}>הוספת ביקור בית חדש</h2>

                                        <div className="row formContainer">

                                            <div className="col-md-6 divBoostrap col-sm-12">
                                                
                                            <InputGroup className="customInput dateInput">
                                            
                                                <div className='dateTimeCont'>

                                                    <div className="customPrepend">תאריך</div>

                                                    <DatePicker 
                                                        showYearDropdown = {true}
                                                        selected={current_datetime}
                                                        dateFormat='dd/MM/yy'
                                                        className='inputPrepend form-control'
                                                        name='created_at'
                                                        locale="he"
                                                        onChange={(date)=>this.handleChangeDate(date,'created_at')}
                                                    />
                                                </div>
                                            </InputGroup>
                                            </div>

                                            <div className="col-md-6 divBoostrap col-sm-12">
                                                
                                                <InputGroup className="customInput customSelect">
                                                    <InputGroup.Prepend className='inputPrependLabel '>
                                                        <InputGroup.Text>{'סוג ביקור'}</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    
                                                    <FormControl
                                                        as="select"
                                                        className='inputPrepend customSelect'
                                                        onChange={this.handleChange} 
                                                        name={'visitType'} 
                                                        value={this.state.visitType}
                                                    >

                                                    <option value="">בחר סוג ביקור</option>
                                                    {choises.map(item => { if (true) {
                                                        /* currentState={this.state} */
                                                        return < option value ={item } key ={ item }> 
                                                                    { item } 
                                                                </ option >
                                                    } else return false}) }
                                                    
                                                    </FormControl>
                                                </InputGroup>
                                            </div>
                                            <br/><br/>

                                            {user.userCredential !== 'socialWorker' ? <div className="col-md-12 divBoostrap col-sm-12">
                                                
                                                <InputGroup className="customInput customSelect">
                                                    <InputGroup.Prepend className='inputPrependLabel '>
                                                        <InputGroup.Text>שיוך לעובד סוציאלי</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    
                                                    <FormControl
                                                        as="select"
                                                        className='inputPrepend customSelect'
                                                        onChange={this.handleChange} 
                                                        name='chosenSocialWorker'
                                                        value={this.state.ChosensocialWorker}
                                                    >

                                                    <option value="">בחר...</option>
                                                    
                                                    {this.props.pageData.socialWorkersList.map(item => { if (true) {
                                                        /* currentState={this.state} */
                                                        return < option value ={item.id } key ={ item.id }> 
                                                                    { item.name } 
                                                                </ option >
                                                    } else return false}) }
                                                    
                                                    </FormControl>
                                                </InputGroup>
                                            </div> : false }

                                            <div className="col-md-12 divBoostrap col-sm-12" style={{margin : '20px 0 0 0'}}>
                                                
                                                <InputGroup className="customInput textArea">
                                                
                                                    <Form.Label>הערות:</Form.Label>

                                                    <FormControl
                                                        as="textarea" rows={3}
                                                        className='inputPrepend'
                                                        onChange={this.handleChange} 
                                                        name='comments' 
                                                        value={this.state.comments}
                                                    >
                                                    
                                                    </FormControl>
                                                </InputGroup>
                                            </div>

                                            <div className="col-md-12 divBoostrap col-sm-12">
                                            <div className="signContainer">

                                                <button className="form-control eraseBtn" onClick={this.clear}>
                                                    <i className="far fa-trash-alt"/>
                                                </button>

                                                <h3>חתימה</h3>
                                                <SignatureCanvas
                                                    ref={(ref) => { this.sigPad = ref }}
                                                    // Pre fill sign:
                                                    //ref = {(ref) => {ref.fromDataURL(this.state.imgfromDb)}}
                                                    onEnd={this.endSign}
                                                    onBegin= {this.onBegin}
                                                    backgroundColor='white'
                                                    penColor='black'
                                                    canvasProps={{width: 320, height: 200, className: 'sigCanvas'}} />
                                                    <div>
                                                    {/*  <button onClick={this.trim}>
                                                        Trim
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className="clear">
                                                <Button className="sendForm" onClick={()=>this.submitVisit()} size="lg" variant="success" >הוספה</Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                        </div>


                </section> : false }


                 {/* https://react-bootstrap.netlify.app/components/modal/ */}
                 <Modal
                        className='animate__animated animate__fadeInDown animate__faster modalErrors'
                        animation={false}
                        onHide={() => this.modalErrors(false)}
                        /* size="sm" // בגודל קטן!!! */
                        /* size="lg" // גדול!! */
                        // backdrop="static" // חייב ללחוץ על כפתור!
                        /* centered // VERTICA CENTER */
                        show={this.state.modalShow}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <span>שגיאה. נא למלא את השדות חובה</span>
                            </Modal.Title>
                            <Button onClick={() => this.modalErrors(false)} variant="secondary" >X</Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="errorCheck">
                                {!this.state.visitType ? <p>סוג ביקור</p> : false }
                                {!this.state.trimmedDataURL ? <p>חתימה</p> : false }
                                {user.userCredential !== 'socialWorker' && !this.state.chosenSocialWorker ? <p>יש לבחור עובד סוציאלי</p> : false }
                                
                            </div>
                        </Modal.Body>
                    </Modal>

                
            </div>
        )
    }


}
